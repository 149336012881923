<template>
  <b-container fluid>
    <ValidationObserver ref="productObserver" v-slot="{ invalid }">
      <form>
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-form-group class="col-md-6" :label="$t('Name')" label-for="name">
                <ValidationProvider :name="$t('Name')" rules="required" v-slot="{ errors }">
                  <b-form-input :class="(errors.length > 0 ? ' is-invalid' : '')" v-model="model.formName " type="text"></b-form-input>
                  <b-form-invalid-feedback> {{ $t('ValidationMessage.CannotBeEmpty') }} </b-form-invalid-feedback>
                </ValidationProvider>
              </b-form-group>
              <b-form-group class="col-md-6" :label="$t('Language')" label-for="language">
                <ValidationProvider :name="$t('Language')" rules="required" v-slot="{ errors }">
                  <v-select transition="" v-model="model.culture"
                            :reduce="s => s.culture" label="name"
                            :options="cultures">
                  <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
                  <b-form-invalid-feedback> {{ $t('ValidationMessage.CannotBeEmpty') }} </b-form-invalid-feedback>
                </ValidationProvider>
              </b-form-group>
            </b-row>
            <b-row>
              <b-form-group class="col-md-6" :label="$t('Operation')" label-for="operation">
                <b-form-input v-model="model.treatmentName" type="text"></b-form-input>
              </b-form-group>
            </b-row>
            <b-row>
              <b-form-group class="col-md-12" :label="$t('Description')" label-for="description">
                <vue-editor id="editorDescription" v-model="model.descriptionHtml" :editorToolbar="customToolbar"></vue-editor>
              </b-form-group>
            </b-row>
          </template>
        </iq-card>
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-form-group class="col-md-6">
                <b-form-checkbox v-model="model.showClauses" switch inline size="lg">
                  <span style="font-size:15px !important; vertical-align:middle !important">{{ $t('ShowClauses') }}</span>
                </b-form-checkbox>
              </b-form-group>
              <div v-if="model.showClauses" class="col-md-6" style="text-align:end;">
                <button type="button"
                        class="btn btn-primary btn-sm"
                        @click="addClause()">
                  <font-awesome-icon icon="fa-solid fa-plus" size="lg" />
                  {{$t('AddNewLine')}}
                </button>
              </div>
            </b-row>
            <br v-if="model.showClauses" />
            <b-row v-if="model.showClauses">
              <b-form-group class="col-md-6" :label="$t('ConsentType')" label-for="consentType">
                <ValidationProvider :name="$t('ConsentType')" rules="required" v-slot="{ errors }">
                  <v-select transition="" v-model="model.clauseConsentType"
                            :reduce="s => s.id" label="name"
                            :options="consentTypeList">
                  <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
                  <b-form-invalid-feedback> {{ $t('ValidationMessage.CannotBeEmpty') }} </b-form-invalid-feedback>
                </ValidationProvider>
              </b-form-group>
            </b-row>
            <template v-if="model.showClauses && model.clauses">
              <b-row v-for="(clause, index) in model.clauses" :key="index">
                &ensp;
                <b-form-group class="col-md-6">
                  <b-form-input v-model="clause.text"></b-form-input>
                </b-form-group>
                <div style="margin-top:-1%;">
                  <button type="button"
                          :disabled="index==0"
                          class="btn btn-brand btn-sm"
                          @click="reorderClauses(clause, '+')">
                    <font-awesome-icon icon="fa-regular fa-angle-up" size="lg" />

                  </button>
                  <br />
                  <button type="button"
                          :disabled="index==model.clauses.length-1"
                          class="btn btn-brand btn-sm"
                          @click="reorderClauses(clause, '-')">
                    <font-awesome-icon icon="fa-regular fa-angle-down" size="lg" />
                  </button>
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <b-form-group>
                  <button type="button" class="btn btn-outline-danger btn-sm" @click="removeClause(clause)">
                    <font-awesome-icon icon="fa-regular fa-xmark" size="lg" />
                  </button>
                </b-form-group>
              </b-row>
            </template>
          </template>
        </iq-card>
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-form-group class="col-md-6">
                <b-form-checkbox v-model="model.showSideEffects" switch inline size="lg">
                  <span style="font-size:15px !important; vertical-align:middle !important">{{ $t('ShowSideEffects') }}</span>
                </b-form-checkbox>
              </b-form-group>
              <div v-if="model.showSideEffects" class="col-md-6" style="text-align:end;">
                <button type="button"
                        class="btn btn-primary btn-sm"
                        @click="addSideEffect()">
                  <font-awesome-icon icon="fa-solid fa-plus" size="lg" />
                  {{$t('AddNewLine')}}
                </button>
              </div>
            </b-row>
            <br v-if="model.showSideEffects" />
            <template v-if="model.showSideEffects && model.sideEffects">
              <b-row v-for="(sideEffect, index) in model.sideEffects" :key="index">
                &ensp;
                <b-form-group class="col-md-6">
                  <b-form-input v-model="sideEffect.text"></b-form-input>
                </b-form-group>
                <div style="margin-top:-1%;">
                  <button type="button"
                          :disabled="index==0"
                          class="btn btn-brand btn-sm"
                          @click="reorderSideEffects(sideEffect, '+')">
                    <font-awesome-icon icon="fa-regular fa-angle-up" size="lg" />

                  </button>
                  <br />
                  <button type="button"
                          :disabled="index==model.clauses.length-1"
                          class="btn btn-brand btn-sm"
                          @click="reorderSideEffects(sideEffect, '-')">
                    <font-awesome-icon icon="fa-regular fa-angle-down" size="lg" />
                  </button>
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <b-form-group>
                  <button type="button" class="btn btn-outline-danger btn-sm" @click="removeSideEffect(sideEffect)">
                    <font-awesome-icon icon="fa-regular fa-xmark" size="lg" />
                  </button>
                </b-form-group>
              </b-row>
            </template>
          </template>
        </iq-card>
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-form-group class="col-md-6">
                <b-form-checkbox v-model="model.showConsiderationsBeforeTreatment" switch inline size="lg">
                  <span style="font-size:15px !important; vertical-align:middle !important">{{ $t('ShowConsiderationsBeforeTreatment') }}</span>
                </b-form-checkbox>
              </b-form-group>
              <div v-if="model.showConsiderationsBeforeTreatment" class="col-md-6" style="text-align:end;">
                <button type="button"
                        class="btn btn-primary btn-sm"
                        @click="addConsiderationBeforeTreatment()">
                  <font-awesome-icon icon="fa-solid fa-plus" size="lg" />
                  {{$t('AddNewLine')}}
                </button>
              </div>
            </b-row>
            <br v-if="model.showConsiderationsBeforeTreatment" />
            <template v-if="model.showConsiderationsBeforeTreatment && model.considerationsBeforeTreatment">
              <b-row v-for="(beforeTreatment, index) in model.considerationsBeforeTreatment" :key="index">
                &ensp;
                <b-form-group class="col-md-6">
                  <b-form-input v-model="beforeTreatment.text"></b-form-input>
                </b-form-group>
                <div style="margin-top:-1%;">
                  <button type="button"
                          :disabled="index==0"
                          class="btn btn-brand btn-sm"
                          @click="reorderConsiderationsBeforeTreatment(beforeTreatment, '+')">
                    <font-awesome-icon icon="fa-regular fa-angle-up" size="lg" />

                  </button>
                  <br />
                  <button type="button"
                          :disabled="index==model.clauses.length-1"
                          class="btn btn-brand btn-sm"
                          @click="reorderConsiderationsBeforeTreatment(beforeTreatment, '-')">
                    <font-awesome-icon icon="fa-regular fa-angle-down" size="lg" />
                  </button>
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <b-form-group>
                  <button type="button" class="btn btn-outline-danger btn-sm" @click="removeConsiderationBeforeTreatment(beforeTreatment)">
                    <font-awesome-icon icon="fa-regular fa-xmark" size="lg" />
                  </button>
                </b-form-group>
              </b-row>
            </template>
          </template>
        </iq-card>
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-form-group class="col-md-6">
                <b-form-checkbox v-model="model.showConsiderationsAfterTreatment" switch inline size="lg">
                  <span style="font-size:15px !important; vertical-align:middle !important">{{ $t('ShowConsiderationsAfterTreatment') }}</span>
                </b-form-checkbox>
              </b-form-group>
              <div v-if="model.showConsiderationsAfterTreatment" class="col-md-6" style="text-align:end;">
                <button type="button"
                        class="btn btn-primary btn-sm"
                        @click="addConsiderationAfterTreatment()">
                  <font-awesome-icon icon="fa-solid fa-plus" size="lg" />
                  {{$t('AddNewLine')}}
                </button>
              </div>
            </b-row>
            <br v-if="model.showConsiderationsAfterTreatment" />
            <template v-if="model.showConsiderationsAfterTreatment && model.considerationsAfterTreatment">
              <b-row v-for="(afterTreatment, index) in model.considerationsAfterTreatment" :key="index">
                &ensp;
                <b-form-group class="col-md-6">
                  <b-form-input v-model="afterTreatment.text"></b-form-input>
                </b-form-group>
                <div style="margin-top:-1%;">
                  <button type="button"
                          :disabled="index==0"
                          class="btn btn-brand btn-sm"
                          @click="reorderConsiderationsAfterTreatment(afterTreatment, '+')">
                    <font-awesome-icon icon="fa-regular fa-angle-up" size="lg" />

                  </button>
                  <br />
                  <button type="button"
                          :disabled="index==model.clauses.length-1"
                          class="btn btn-brand btn-sm"
                          @click="reorderConsiderationsAfterTreatment(afterTreatment, '-')">
                    <font-awesome-icon icon="fa-regular fa-angle-down" size="lg" />
                  </button>
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <b-form-group>
                  <button type="button" class="btn btn-outline-danger btn-sm" @click="removeConsiderationAfterTreatment(afterTreatment)">
                    <font-awesome-icon icon="fa-regular fa-xmark" size="lg" />
                  </button>
                </b-form-group>
              </b-row>
            </template>
          </template>
        </iq-card>
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-form-group class="col-md-12" :label="$t('Footnote')" label-for="footnote">
                <vue-editor id="footnote" v-model="model.asteriskClarificationHtml" :editorToolbar="customToolbar"></vue-editor>
              </b-form-group>
            </b-row>
            <b-row>
              <b-form-group class="col-md-12" :label="$t('ConsentText')" label-for="consentText">
                <vue-editor id="consentText" v-model="model.consentText" :editorToolbar="customToolbar"></vue-editor>
              </b-form-group>
            </b-row>
          </template>
        </iq-card>
        <modal-footer-button :set-is-submitting="isSubmitting"
                             @submit="submitCustomConsentForm"></modal-footer-button>
      </form>
    </ValidationObserver>
  </b-container>

</template>
<script>

  import ModalFooterButton from '../../../components/shared/ModalFooterButton';
  import commonService from '../../../services/common';
  import consentFormService from '../../../services/customerConsentForm';
  import '../../../plugins/vue-editor';


  export default {
    name: 'CustomConsentForm',
    components: { ModalFooterButton },
    props: { customConsentFormId: String },
    data() {
      return {
        model: {
          id: '',
          formName: '',
          treatmentName: '',
          culture: '',
          showClauses: false,
          clauseConsentType: null,
          clauses: [],
          descriptionHtml: '',
          showSideEffects: false,
          sideEffects: [],
          showConsiderationsBeforeTreatment: false,
          considerationsBeforeTreatment: [],
          showConsiderationsAfterTreatment: false,
          considerationsAfterTreatment: [],
          consentText: '',
          asteriskClarificationHtml: ''
        },
        cultures: [],
        consentTypeList: [],

        customToolbar: [
          [{ header: [false, 1, 2, 3, 4, 5, 6] }],
          ["bold", "italic", "underline", "strike"], // toggled buttons
          [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" }
          ],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          ["clean"] // remove formatting button
        ],

        isSubmitting: false
      }
    },
    methods: {
      getCustomConsentForm() {
        consentFormService.getConsentForm(this.model.id)
          .then(response => {
            this.model = response;
            this.model.clauseConsentType = response.clauseConsentType == 0 ? null : response.clauseConsentType;
          });
      },
      async submitCustomConsentForm() {
        const isValid = await this.$refs.productObserver.validate();
        if (!isValid) {
          this.$toastr.error(this.$t("ValidationMessage.EmptyAndValidateError"));
          return;
        }
        let url = "/api/CustomConsentForm/New";
        if (this.model.id && this.model.id.length > 0)
          url = "/api/CustomConsentForm/Edit"

        this.isSubmitting = true;

        consentFormService.submitConsentForm(url, this.model).then((response) => {
          this.isSubmitting = false;
          if (response) {
            this.$toastr.success(this.$t("Success"));
            this.$router.push({ name: 'customConsentForm.list' });
          }
          else {
            this.$toastr.error(this.$t("Error"));
          }
        }).finally(() => { this.isSubmitting = false; });
      },
      removeConsiderationAfterTreatment(afterTreatment) {
        this.model.considerationsAfterTreatment.splice(this.model.considerationsAfterTreatment.indexOf(afterTreatment), 1);
      },
      reorderConsiderationsAfterTreatment(afterTreatment, direction) {
        if (direction == '+') {
          let upper = this.model.considerationsAfterTreatment.find(s => s.displayOrder == afterTreatment.displayOrder - 1);
          if (upper != null)
            upper.displayOrder++;
          afterTreatment.displayOrder--;
        }
        else {
          let bottom = this.model.considerationsAfterTreatment.find(s => s.displayOrder == afterTreatment.displayOrder + 1);
          if (bottom != null)
            bottom.displayOrder--;
          afterTreatment.displayOrder++;
        }

        this.model.considerationsAfterTreatment.sort((a, b) => parseFloat(a.displayOrder) - parseFloat(b.displayOrder));
      },
      addConsiderationAfterTreatment() {
        this.model.considerationsAfterTreatment.push({ id: '', customConsentFormID: this.model.id, text: '', displayOrder: this.model.clauses.length + 1 });
      },
      removeSideEffect(sideEffect) {
        this.model.sideEffects.splice(this.model.sideEffects.indexOf(sideEffect), 1);
      },
      addConsiderationBeforeTreatment() {
        this.model.considerationsBeforeTreatment.push({ id: '', customConsentFormID: this.model.id, text: '', displayOrder: this.model.clauses.length + 1 });
      },
      prepareConsentTypeList() {
        this.consentTypeList.push({ id: -1, name: this.$t('NoToAll') });
        this.consentTypeList.push({ id: 1, name: this.$t('YesToAll') });
      },
      getLanguagesList() {
        commonService.getLanguagesList().then((response) => {
          this.cultures = response.filter(s => s.culture == 'en-US' || s.culture == 'tr-TR');
        });
      },
      addSideEffect() {
        this.model.sideEffects.push({ id: '', customConsentFormID: this.model.id, text: '', displayOrder: this.model.clauses.length + 1 });
      },
      reorderConsiderationsBeforeTreatment(beforeTreatment, direction) {
        if (direction == '+') {
          let upper = this.model.considerationsBeforeTreatment.find(s => s.displayOrder == beforeTreatment.displayOrder - 1);
          if (upper != null)
            upper.displayOrder++;
          beforeTreatment.displayOrder--;
        }
        else {
          let bottom = this.model.considerationsBeforeTreatment.find(s => s.displayOrder == beforeTreatment.displayOrder + 1);
          if (bottom != null)
            bottom.displayOrder--;
          beforeTreatment.displayOrder++;
        }

        this.model.considerationsBeforeTreatment.sort((a, b) => parseFloat(a.displayOrder) - parseFloat(b.displayOrder));
      },
      removeConsiderationBeforeTreatment(beforeTreatment) {
        this.model.considerationsBeforeTreatment.splice(this.model.considerationsBeforeTreatment.indexOf(beforeTreatment), 1);
      },
      addClause() {
        this.model.clauses.push({ id: '', customConsentFormID: this.model.id, text: '', displayOrder: this.model.clauses.length + 1 });
      },
      reorderClauses(clause, direction) {
        if (direction == '+') {
          let upper = this.model.clauses.find(s => s.displayOrder == clause.displayOrder - 1);
          if (upper != null)
            upper.displayOrder++;
          clause.displayOrder--;
        }
        else {
          let bottom = this.model.clauses.find(s => s.displayOrder == clause.displayOrder + 1);
          if (bottom != null)
            bottom.displayOrder--;
          clause.displayOrder++;
        }

        this.model.clauses.sort((a, b) => parseFloat(a.displayOrder) - parseFloat(b.displayOrder));
      },
      removeClause(clause) {
        this.model.clauses.splice(this.model.clauses.indexOf(clause), 1);
      },
      reorderSideEffects(sideEffect, direction) {
        if (direction == '+') {
          let upper = this.model.sideEffects.find(s => s.displayOrder == sideEffect.displayOrder - 1);
          if (upper != null)
            upper.displayOrder++;
          sideEffect.displayOrder--;
        }
        else {
          let bottom = this.model.sideEffects.find(s => s.displayOrder == sideEffect.displayOrder + 1);
          if (bottom != null)
            bottom.displayOrder--;
          sideEffect.displayOrder++;
        }

        this.model.sideEffects.sort((a, b) => parseFloat(a.displayOrder) - parseFloat(b.displayOrder));
      },
    },
    watch: {
      'model.showClauses': function (val) {
        if (val == true && !this.model.id && this.model.clauses?.length == 0) {
          this.model.clauses.push({ customConsentFormID: this.model.id, text: '', displayOrder: 1 });
        }

        if (val == false) {
          this.model.clauses = [];
          this.model.clauseConsentType = null;
        }
      },
      'model.showSideEffects': function (val) {
        if (val == true && !this.model.id && this.model.sideEffects?.length == 0) {
          this.model.sideEffects.push({ customConsentFormID: this.model.id, text: '', displayOrder: 1 });
        }

        if (val == false) {
          this.model.sideEffects = [];
        }
      },
      'model.showConsiderationsBeforeTreatment': function (val) {
        if (val == true && !this.model.id && this.model.considerationsBeforeTreatment?.length == 0) {
          this.model.considerationsBeforeTreatment.push({ customConsentFormID: this.model.id, text: '', displayOrder: 1 });
        }

        if (val == false) {
          this.model.considerationsBeforeTreatment = [];
        }
      },
      'model.showConsiderationsAfterTreatment': function (val) {
        if (val == true && !this.model.id && this.model.considerationsAfterTreatment?.length == 0) {
          this.model.considerationsAfterTreatment.push({ customConsentFormID: this.model.id, text: '', displayOrder: 1 });
        }

        if (val == false) {
          this.model.considerationsAfterTreatment = [];
        }
      }
    },
    mounted() {
      if (this.customConsentFormId) {
        this.model.id = this.customConsentFormId;
        this.getCustomConsentForm();
      }

      this.getLanguagesList();
      this.prepareConsentTypeList();

    }
  }
</script>

