import axios from './index'

export default {
  getConsentFormList() {
    return axios.get('/consentForm/getConsentForms')
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  insertCustomerConsentForm(url) {
    return axios.post(url)
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getConsentForm(id) {
    return axios.get('/api/customConsentForm/getCustomConsentFormForEdit?id=' + id)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getCustomConsentFormPreview(id) {
    return axios.get('/api/customConsentForm/getCustomConsentFormPreview?customConsentFormId=' + id)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  submitConsentForm(url, model) {
    return axios.post(url, model)
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  }
}
